import {expressEventListener, expressQuerySelector, expressQuerySelectorAll, expressRemoveClass} from "../common/html";
import { debounce } from "../utils/debounce";
import { calculateAndSetHeightOfMobileNavigationLevel } from "../utils/mobile-menu";
import { createTruncate, ITruncate, ITruncateOptions } from "./truncate";
export enum Device {
	Desktop = 1,
	Tablet = 2,
	Mobile = 3
}

export function createImageDescription(containerEl: HTMLElement) {
	const truncateEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-shave');
	const getDevice = (): Device => window.innerWidth <= 767 ? Device.Mobile : Device.Desktop;
	let currentDevice: Device = getDevice();
	let truncatedEls: ITruncate[] = [];
	const useMaxHeight = expressQuerySelectorAll(containerEl, '.technical-max-width').length > 0;
	const button = expressQuerySelector<HTMLButtonElement>(containerEl, '.technical-image-description-button', false);

	const outerHeight = (el?: HTMLElement, includeOffset = true) => {
		if (!el) return 0;
		let height = includeOffset ? el.offsetHeight : 0;
		const style = getComputedStyle(el);
		height += parseInt(style.marginTop || '0', 10) + parseInt(style.marginBottom || '0', 10);
		return height;
	};

	const calculateTruncateMaxHeight = (): number => {
		let maxHeight = outerHeight(expressQuerySelector(containerEl, '.technical-image-container', false) || expressQuerySelector(containerEl, '.technical-video-container', false));
		if (!maxHeight) return undefined;

		const headerStyle = getComputedStyle(expressQuerySelector(containerEl, '.m-image-description__header', false));

		maxHeight -= (parseInt(headerStyle.marginTop || '0') + parseInt(headerStyle.marginBottom || '0')); // header margins around the text
		maxHeight -= outerHeight(expressQuerySelector(containerEl, '.m-image-description__title-content', false)); // titleEl => take element with margin to calculate this in the maxheight
		maxHeight -= outerHeight(button);

		maxHeight += parseInt(headerStyle.lineHeight || '1') * 2; // for special cases that need a few extra pixels to show there content

		return maxHeight;
	};

	const removeSkeletons = () => {
		const skeletonEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.skeleton-loading');
		skeletonEls.forEach(el => expressRemoveClass(el, 'skeleton-loading'));
	};

	const getTruncateOptions = (useMaxHeight: boolean): ITruncateOptions => {
		switch (currentDevice) {
			case Device.Mobile: return { lines: button ? 4 : 999, onInitEvent: removeSkeletons};
			case Device.Desktop: return {
				lines: button ? 4 : undefined,
				maxHeight: useMaxHeight ? calculateTruncateMaxHeight() : undefined,
				onInitEvent: removeSkeletons
			};
			default: return { onInitEvent: removeSkeletons };
		}
	};

	const onResize = () => {
		const device = getDevice();
		if (currentDevice == device) return;

		currentDevice = device;
		truncatedEls.forEach(t => t.updateConfig(getTruncateOptions(useMaxHeight), true));
	};

	// init
	truncatedEls = truncateEls.map(el => createTruncate(el, getTruncateOptions(useMaxHeight)));
	if (!truncateEls || truncateEls.length === 0) {
		removeSkeletons();
	}
	button && button.getAttribute('href') && button.addEventListener('click', _ => window.location.href = button.getAttribute('href'));
	expressEventListener(window, "resize", debounce(onResize, 250));
}
