import { expressQuerySelector, pageVisibilityChange } from "../common/html";

export function createAbandonedVisitTitleSwapper() {
	const origTitle = document.title;
	const titleEl = expressQuerySelector(document, 'title', true);
	if (titleEl.dataset.abandonedvisitenabled && titleEl.dataset.abandonedvisitenabled.toLowerCase() === "false") return;
	let blinkInterval: number | null;
	const blinkTitle = () => {
		const currentTitle = document.title;
		if (currentTitle === titleEl.dataset.abandonedvisittitle)
			document.title = titleEl.dataset.abandonedvisittitlealternate;
		else
			document.title = titleEl.dataset.abandonedvisittitle;
	};

	const onVisible = () => {
		clearInterval(blinkInterval);
		blinkInterval = null;
		document.title = origTitle;
	};
	const onHidden = () => {
		if (!blinkInterval) {
			const interval = parseInt(titleEl.dataset.abandonedvisitspeed, 10) || 2000;
			// @ts-ignore
			blinkInterval = setInterval(blinkTitle, interval);
		}
	};

	pageVisibilityChange(onVisible, onHidden);
}
