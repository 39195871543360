﻿export function isValidTextWithoutAngleBrackets(text: string) {
	if (!text) return false;
	return new RegExp("^[^<>]+$").test(text);
}

export function isValidEmailAddress (email: string) {
	const sValidEmail = '^((([a-zA-Z]|\\d|!|#|\\$|\\[|\\]|%|&|\'|\\\\|\\/|\\+|\\-|=|\\?|\\^|\\*|_|{|}|`|\\||~)+(\\.([a-zA-Z]|\\d|!|#|\\$|\\[|\\]|%|&|\'|\\\\|\\/|\\+|\\-|=|\\?|\\^|\\*|_|{|}|`|\\||~)+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e])|(([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-zA-Z]|\\d)|(([a-zA-Z]|\\d)([a-zA-Z]|\\d|-|\\.|_|~)*([a-zA-Z]|\\d)))\\.)+(([a-zA-Z])|(([a-zA-Z])([a-zA-Z]|\\d|-|\\.|_|~)*([a-zA-Z])))$';
	const reValidEmail = new RegExp(sValidEmail);
	return reValidEmail.test(punycode.toUnicode(email));
}

export function isValidWithoutNonAlphaNumericCharsAtEnd(text: string, el: HTMLElement) {
	// Our zipcode db contains values like 'ANTWERPEN (2000)' as city => these should be valid => if last == ) return true
	const reg = new RegExp('^[\\p{L}\\p{N}]*$', 'u');
	const elName = el.getAttribute("name") || "";
	// Why trim? We allow spaces as we trim those in the backend code
	const alsoAlsoAllowBracket = elName && elName.toLowerCase().indexOf('city') !== -1;
	const last = text.trim().charAt(text.trim().length - 1);
	return reg.test(last) || (alsoAlsoAllowBracket && last === ')') || last === '.';
}

export function canNotContainOnlyNumerics(text: string) {
	const reg = new RegExp('^[0-9]+$');
	return !reg.test(text);
}

export function isValidOrderNumber(orderNr: string) {
	return onlyNumber(orderNr) && orderNr.length === 14;
}

export function onlyNumber(input: string): boolean {
	return Boolean(input.match(/^[0-9]*$/));
}

export function isTempAccountEmail(email: string): boolean {
	return email.indexOf('TEMP_ACCOUNT_') >= 0;
}
