﻿import {expressQuerySelector, isElementVisible} from "../common/html";
import {debounce} from "../utils/debounce";

export function createScrollToTop()
{
	const scrollToTopEl = expressQuerySelector<HTMLElement>(document, ".technical-scroll-to-top", false);
	const init = () => {
		const wobblerEl = expressQuerySelector<HTMLElement>(document, ".m-wobbler", false);
		const stickyCtaButtonEl = expressQuerySelector<HTMLElement>(document, ".technical-mobile-cta-button", false);
		const footerEl = expressQuerySelector<HTMLElement>(document, "footer", false);
		let fade;
		let displayNone;
		if (wobblerEl && stickyCtaButtonEl && isElementVisible(stickyCtaButtonEl)) {
			scrollToTopEl!.classList.add('m-scroll-to-top--has-wobbler--has-sticky-button');
		} else {
			if (wobblerEl) scrollToTopEl.classList.add('m-scroll-to-top--has-wobbler');
			if (stickyCtaButtonEl && isElementVisible(stickyCtaButtonEl)) scrollToTopEl.classList.add("m-scroll-to-top--has-sticky-button");
		}

		const isIOS = (): boolean => {
			const iosQuirkPresent = () => {
				const audio = new Audio();
				audio.volume = 0.5;
				return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
			};
			const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
			const isAppleDevice = navigator.userAgent.indexOf('Macintosh') !== 0;
			const isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13
			return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
		};

		const setBottomStyle = () => {
			if (wobblerEl && stickyCtaButtonEl && isElementVisible(stickyCtaButtonEl)) {
				if (footerEl) footerEl.style.marginBottom = stickyCtaButtonEl.clientHeight + "px";
				if (stickyCtaButtonEl.clientHeight  > 60) scrollToTopEl.style.bottom = "125px";
				else scrollToTopEl.style.bottom = "110px";
			} else if (stickyCtaButtonEl && isElementVisible(stickyCtaButtonEl)) {
				if (footerEl) footerEl.style.marginBottom = stickyCtaButtonEl.clientHeight + "px";
				if (stickyCtaButtonEl.clientHeight  > 60) scrollToTopEl.style.bottom = "105px";
				else scrollToTopEl.style.bottom = "90px";
			} else {
				scrollToTopEl.style.bottom = "";
				if (footerEl) footerEl.style.marginBottom = "";
			}
		};

		const showBtn = (num: number) => {
			if (document.documentElement.scrollTop >= num ) {
				clearTimeout(fade);
				fade = setTimeout( function() {
					clearTimeout(displayNone);
					scrollToTopEl!.classList.remove('u-hide');
					setTimeout(function () {
						scrollToTopEl!.classList.remove('m-scroll-to-top--invisible');
					}, 100);
				}, 100);
			} else {
				clearTimeout(fade);
				fade = setTimeout( function() {
					scrollToTopEl!.classList.add('m-scroll-to-top--invisible');
					displayNone = setTimeout(function () {
						scrollToTopEl!.classList.add('u-hide');
					}, 500);
				}, 100);
			}
		};

		const onResize = () => {
			if (!stickyCtaButtonEl) return;
			if (isElementVisible(stickyCtaButtonEl))
			{
				if (wobblerEl) scrollToTopEl!.classList.add('m-scroll-to-top--has-wobbler--has-sticky-button');
				else scrollToTopEl.classList.add("m-scroll-to-top--has-sticky-button");
			} else {
				if (wobblerEl) {
					scrollToTopEl!.classList.remove('m-scroll-to-top--has-wobbler--has-sticky-button');
					scrollToTopEl.classList.add('m-scroll-to-top--has-wobbler');
				} else {
					scrollToTopEl.classList.remove("m-scroll-to-top--has-sticky-button");
				}
			}
			setBottomStyle();
		};

		window.addEventListener('scroll', () => { showBtn(20); });
		window.addEventListener('resize', () => { debounce(onResize, 100); });

		if (scrollToTopEl) {
			scrollToTopEl.addEventListener("click", () => {
				window.scrollTo({ top: 0, behavior: "smooth" });
				if (isIOS()) debounce(() => { window.scrollTo({ top: 0, behavior: "smooth" }); }, 100);
			});
		}

		setBottomStyle();
	};

	scrollToTopEl && init();
}
